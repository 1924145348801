<template>
    <div class="container">
        <div class="commonList">
            <table v-loading="isLoading">
                <thead>
                    <tr>
                        <td>任务ID</td>
                        <td>任务名称</td>
                        <td>任务描述</td>
                        <td>最近一次执行时间</td>
                        <td>最近一次执行时间</td>
                        <td>最近一次执行状态</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in dataList" :key="index" :class="{error:item.last_execution_status == 'error'?true:false}">
                        <td>{{item.id}}</td>
                        <td>{{item.name}}</td>
                        <td style="max-width:400px">{{item.des}}</td>
                        <td>{{item.last_execution_time | getDiffToToday}}</td>
                        <td>{{item.last_execution_time }}</td>
                        <td>
                            <span v-if="item.last_execution_status == 'normal'">正常</span>
                            <span v-else-if="item.last_execution_status == 'error'">错误</span>
                            <span v-else></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return {
            dataList:[],
            isLoading:false,
        }
    },
    mounted(){
        this.loadData();
    },
    methods:{
        loadData(){
            this.isLoading = true;
            this.$api.taskListPost().then(res => {
                if(res.code == 0){
                    this.dataList = res.data;
                }
            }).finally(()=>{
                this.isLoading = false;
            });
        }
    }
    
}
</script>

<style scoped>

tr.error{
    color:red;
}

</style>